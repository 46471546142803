'use client'

import { datadogLogs } from '@datadog/browser-logs'
import { useEffect } from 'react'

import ErrorTemplate from '~/modules/error-template'
import { publicConfig } from '~/config'

const Error = ({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) => {
	useEffect(() => {
		datadogLogs.logger.error('Error occured', {}, error)
	}, [
		error,
	])

	const message = publicConfig.app.isDev ? error.message : 'Something went wrong!'

	return (
		<ErrorTemplate
			type="error"
			message={message}
			onClick={() => {
				reset()
			}}
		/>
	)
}

export default Error
